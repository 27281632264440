import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// import Students from "../components/StudentList";
import Seo from "../../components/Seo";
import Layout from "../../components/Layout";
import CollectionList from "../../components/CollectionList";

import AllMenu from "../../components/AllMenu";

const Students = ({ data }) => {
  //get each object out of node...
  const studentList = data.allContentfulStudent.edges.map((a) => a.node);

  return (
    <Layout>
      <Seo title={`Kingston School of Art Foundation 2021 • Students`} />

      <div className="studentListContainer allTop">
        <AllMenu />

        <CollectionList students={studentList} projectsOrStudents="students" />
      </div>
    </Layout>
  );
};

export default Students;

export const pageQuery = graphql`
  {
    allContentfulStudent(sort: { order: ASC, fields: studentName }) {
      edges {
        node {
          collection {
            lineNumber
          }
          specialism
          studentName
          slug
          id
          heroImage {
            gatsbyImageData
            id
            title
          }
        }
      }
    }
  }
`;
